import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UIKitIconModule } from '../icon';
import { UIKitRadioButton } from './radio';

@NgModule({
    declarations: [UIKitRadioButton],
    imports: [CommonModule, FormsModule, UIKitIconModule],
    exports: [UIKitRadioButton]
})
export class UIKitRadioModule { }
