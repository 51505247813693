import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexIcon'
})
export class IndexIconPipe implements PipeTransform {

  transform(indexKey: string, ...args: unknown[]): string {
    switch (indexKey?.toLowerCase()) {
      case 'ei':
        return 'rocket'
      case 'tei':
        return 'tei'
      case 'lsi':
        return 'leadership'
      case 'osi':
        return 'osi'
      case 'enps':
        return 'enps'
      case 'mi':
        return 'management'
    }
    return 'bar2';
  }

}
