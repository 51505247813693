import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxDatePickerModule, IgxInputGroupModule } from '@infragistics/igniteui-angular';
import { UIKitIconModule } from '../icon';
import { UIKitDateTimePicker } from './date-time-picker.component';
import { TimePickerOverlayComponent } from './time-picker-overlay/time-picker-overlay.component';

@NgModule({
  declarations: [UIKitDateTimePicker, TimePickerOverlayComponent],
  imports: [
    CommonModule,
    IgxDatePickerModule,
    IgxInputGroupModule,
    UIKitIconModule
  ],
  exports: [UIKitDateTimePicker]
})
export class UIKitDateTimePickerModule { }
