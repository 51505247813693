import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { FeatureFlagType } from './feature-flag-type';

@Directive({
  selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {

  private requiredFlag: string = '';
  private isHidden = true;

  @Input() set featureFlag(key: FeatureFlagType) {
    if (key) {
      this.requiredFlag = key.toString();
      this.updateView();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlags: FeatureFlagsService
  ) { }

  ngOnInit() {
    this.updateView();
  }

  private updateView() {
    if (this.checkValidity()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.viewContainer.clear();
      this.isHidden = true;
    }
  }

  private checkValidity() {
    return (
      this.requiredFlag &&
      this.featureFlags.isFeatureFlagEnabled(this.requiredFlag)
    );
  }

}
