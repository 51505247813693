import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIKitFormFieldModule } from '../form-field/form-field.module';
import { UIKitInput } from './input';

@NgModule({
  declarations: [UIKitInput],
  imports: [
    CommonModule,
    TextFieldModule,
    UIKitFormFieldModule
  ],
  exports: [
    UIKitInput,
    TextFieldModule,
    UIKitFormFieldModule
  ]
})
export class UIKitInputModule { }
