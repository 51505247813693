import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-time-picker-overlay',
  templateUrl: './time-picker-overlay.component.html',
  styleUrls: ['./time-picker-overlay.component.scss']
})
export class TimePickerOverlayComponent implements OnInit {

  timeChange = new EventEmitter<string>();
  time: string;
  minuteStep: number;

  constructor() { }

  ngOnInit() {
  }

  timeChanged(event: Event) {
     this.timeChange.emit(event.target['value']);
  }

}
