import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {

  private featureFlags: Record<string, boolean>;

  constructor(private configurationService: ConfigurationService) {
    this.featureFlags = this.configurationService.configuration?.FeatureFlags;
   }


  isFeatureFlagEnabled(key: string) {
    return this.featureFlags
      && key in this.featureFlags
      && this.featureFlags[key]
  }

}
