import { Injectable } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { UIKitRadioButton } from './radio';

@Injectable({
    providedIn: 'root',
})
export class UIKitRadioService {
    private radioButtons: UIKitRadioButton[] = [];

    add(radio: UIKitRadioButton) {
        this.radioButtons.push(radio);
    }

    remove(accessor: UIKitRadioButton) {
        this.radioButtons.remove(accessor);
    }

    select(selectedRadio: UIKitRadioButton) {

        for (const radio of this.radioButtons) {
            if (radio.control?.control.root === selectedRadio.control?.control &&
                radio !== selectedRadio) {
                radio.writeValue(selectedRadio.value);
            }
        }
    }
}
