import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIKitButtonModule } from './button';
import { UIKitDatePickerModule } from './date-picker';
import { UIKitDateTimePickerModule } from './date-time-picker';
import { UIKitFormFieldModule } from './form-field/form-field.module';
import { UIKitIconModule } from './icon';
import { UIKitInputModule } from './input/input.module';
import { UIKitRadioModule } from './radio/radio.module';
import { UIKitSelectModule } from './select/select.module';
import { UIKitSquareButtonModule } from './square-button/square-button.module';
import { UIKitStepperModule } from './stepper/stepper.module';
import { UIKitTooltipModule } from './tooltip/tooltip.module';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import {UIKitActionListModule} from "app/shared/ui-kit/action-list/action-list.module";
@NgModule({
  imports: [
    CommonModule,
    UIKitFormFieldModule,
    UIKitButtonModule,
    UIKitIconModule,
    UIKitInputModule,
    UIKitDatePickerModule,
    UIKitDateTimePickerModule,
    UIKitStepperModule,
    UIKitTooltipModule,
    UIKitRadioModule,
    UIKitSelectModule,
    UIKitSquareButtonModule,
    AutoSizeInputModule,
    UIKitActionListModule
  ],
  exports: [
    UIKitFormFieldModule,
    UIKitButtonModule,
    UIKitIconModule,
    UIKitInputModule,
    UIKitDatePickerModule,
    UIKitDateTimePickerModule,
    UIKitStepperModule,
    UIKitTooltipModule,
    UIKitRadioModule,
    UIKitSelectModule,
    UIKitSquareButtonModule,
    UIKitActionListModule
  ]
})
export class UIKitModule { }
