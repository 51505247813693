import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/state/app.state';
import * as fromRoot from 'app/state/selectors/product-wizard.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'uikit-step',
  template: '<cdk-step><ng-content></ng-content></cdk-step>'
})
export class UIKitStep {
}

@Component({
  selector: 'uikit-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: UIKitStepper }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitStepper extends CdkStepper implements AfterViewInit {
  @Input() productId: number;
  @Input() finishButtonText: string;
  @Output() finish = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() close = new EventEmitter();

  scrollContainer: HTMLElement;
  saving$: Observable<boolean> = this.store.select(fromRoot.selectProductWizardSaving());
  unsaved$: Observable<boolean> = this.store.select(fromRoot.selectProductWizardUnsaved());

  constructor(dir: Directionality, private changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>, private store: Store<AppState>) {
    super(dir, changeDetectorRef, elementRef);
  }


  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.scrollContainer = document.querySelector('.insight-container');
  }

  onClick(index: number): void {
    this.selectedIndex = index;
  }

  isValidToSubmit(): boolean {
    return this.steps
      .map(step => step.completed)
      .reduce((x, y) => x && y, true);
  }

  finishAction(): void {
    if (this.isValidToSubmit()) {
      this.finish.emit(true);
    }
  }

  showToTop(): boolean {
    return this.scrollContainer?.scrollTop > 100;
  }

  scrollToTop(): void {
    this.scrollContainer.scroll({ top: 0, behavior: 'smooth' });
  }

  onSave(): void {
    this.save.emit(true);
  }

  onClose(): void {
    this.close.emit(true);
  }
}
