import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewChild
} from "@angular/core";
import {MatMenu} from "@angular/material/menu";

@Component({
  selector: 'uikit-action-menu',
  exportAs: 'uikitActionMenu',
  template: `<mat-menu #matMenu="matMenu">
    <ng-template matMenuContent let-data="data">
      <ng-container *ngTemplateOutlet="menuTemplate; context: {data: data}"></ng-container>
    </ng-template>
  </mat-menu>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitActionMenuComponent {
  @ViewChild('matMenu', {read:MatMenu}) contextMenuTrigger: MatMenu;
  @Input() menuTemplate!: TemplateRef<any>;
}

@Component({
  selector: 'uikit-action-menu-item',
  template: `<button mat-menu-item><ng-content></ng-content></button>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitActionMenuItemComponent {
}

@Component({
  selector: 'uikit-action-item',
  template: `
    <li class="action-list-item">
      <div class="icon-box" *ngIf="icon">
        <uikit-icon icon="{{icon}}"></uikit-icon>
      </div>
      <div class="row-title">{{label}}</div>
      <div class="action-menu" *ngIf="menu">
        <button uikit-button menu-button
                [matMenuTriggerFor]="menu.contextMenuTrigger"
                [matMenuTriggerData]="{data: data}"
        ><uikit-icon icon="dots"></uikit-icon></button>
      </div>
    </li>
  `,
  styleUrls: ['./action-item.component.scss'],
  exportAs: 'actionItem',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitActionItemComponent<T> {
  @Input() label = '';
  @Input() icon = '';
  @Input() data: T;
  @Input() menu: UIKitActionMenuComponent;

  constructor() {
  }
}

@Component({
  selector: 'uikit-action-list',
  template: `
    <ul class="action-list">
      <ng-content></ng-content>
    </ul>
  `,
  styleUrls: ['./action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitActionListComponent {

}

