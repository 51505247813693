import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIKitRadioService } from './radio.service';

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIKitRadioButton),
  multi: true
};

@Component({
  selector: 'uikit-radio',
  templateUrl: './radio.html',
  providers: [RADIO_VALUE_ACCESSOR],
  styleUrls: ['radio.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitRadioButton implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() value: any;
  @Input() formControlName: string;
  @Input() name: string;
  @Input() @HostBinding('class.disabled') disabled: boolean;
  @Input() tabindex: number;
  @Input() inputId: string;
  @Input() ariaLabelledBy: string;
  @Input() ariaLabel: string;

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() focused: EventEmitter<any> = new EventEmitter<any>();
  @Output() blured: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('rb') inputViewChild: ElementRef;

  @HostBinding('class') class = 'uikit-radio';

  @HostBinding('class.checked') public checked: boolean;

  @HostBinding('class.focused') public hasFocus: boolean;

  control: NgControl;

  public onModelChange: (value: any) => unknown;
  public onModelTouched: () => void = () => null;

  get icon() { return this.checked ? 'radio-checked' : 'radio-unchecked'; }

  constructor(public cd: ChangeDetectorRef, private injector: Injector, private registry: UIKitRadioService) { }

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.checkName();
    this.registry.add(this);
  }

  select(event: unknown) {
    if (!this.disabled) {
      (this.inputViewChild.nativeElement as HTMLInputElement).checked = true;
      this.checked = true;
      this.onModelChange(this.value);
      this.registry.select(this);
      this.clicked.emit(event);
    }
  }

  writeValue(value: any): void {
    this.checked = (value === this.value);

    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      (this.inputViewChild.nativeElement as HTMLInputElement).checked = this.checked;
    }

    this.cd.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  onInputFocus(event) {
    this.hasFocus = true;
    this.focused.emit(event);
  }

  onInputBlur(event) {
    this.hasFocus = false;
    this.onModelTouched();
    this.blured.emit(event);
  }

  onChange(event) {
    this.select(event);
  }

  focus() {
    (this.inputViewChild.nativeElement as HTMLInputElement).focus();
  }

  ngOnDestroy() {
    this.registry.remove(this);
  }

  private checkName() {
    if (this.formControlName && this.name !== this.formControlName) {
      if (this.name) {
        console.warn(`
        If you define both a name and a formControlName attribute on your radio button, their values
        should match. name attribute will be overwritten with formControlName.
      `);
      }
      this.name = this.formControlName;
    }
  }
}
