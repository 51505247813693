import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateRangeType, IgxDatePickerComponent } from '@infragistics/igniteui-angular';
import { TimePickerOverlayComponent } from './time-picker-overlay/time-picker-overlay.component';


@Component({
  selector: 'uikit-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitDateTimePicker implements OnInit {

  hour = 0;
  minute = 0;
  private _model: Date;
  @Input() set model(date: Date) {
    if (date) {
      date.setHours(this.hour, this.minute, 0);
    }

    this._model = date;
    this.modelChange.emit(this._model);
  }
  get model(): Date { return this._model; }
  @Output() modelChange = new EventEmitter();

  private _disabledDates: any[] = [];
  @Input() set disabledDates(disabledDates: any[]) {
    this._disabledDates = disabledDates;
  }
  get disabledDates(): any[] { return this._disabledDates; }


  private _label: string;
  @Input() set label(label: string) {
    this._label = label;
  }
  get label(): string { return this._label; }

  @Input() minuteStep = 15;
  @Input() min: Date;
  @Input() max: Date;
  @Output() change: EventEmitter<Date> = new EventEmitter<Date>();
  @ViewChild('picker', { static: false }) picker: IgxDatePickerComponent;

  public formatter = (date: Date) => {
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    return `${date.getFullYear()}-${month.length > 1 ? month : '0' + month}-${day.length > 1 ? day : '0' + day} ${hours.length > 1 ? hours : '0' + hours}:${minutes.length > 1 ? minutes : '0' + minutes}`;
  }
  constructor(private overlay: Overlay) { }
  ngOnInit(): void {
    if (this.min) {
      this.disabledDates.push({ type: DateRangeType.Before, dateRange: [new Date(this.min)] });
    }
    if (this.max) {
      this.disabledDates.push({ type: DateRangeType.After, dateRange: [new Date(this.max)] });
    }
  }
  selection(date: Date) {
    this.change.emit(date);
  }
  dateChanged(value: Date) {
    if (!value) {
      this.model = undefined;
      return;
    }
    if (value > this.max || value < this.min) { return; }
    this.model = new Date(value);
  }
  showTimePicker() {
    const position = this.overlay.position()
      .flexibleConnectedTo(this.picker.element)
      .withPositions([{
        originX: 'start', originY: 'bottom',
        overlayX: 'start', overlayY: 'top'
      }]);

    const overlayRef = this.overlay.create(new OverlayConfig({
      positionStrategy: position,
      hasBackdrop: true,
      width: `${this.picker.element.nativeElement.offsetWidth}px`,
      backdropClass: 'mat-overlay-transparent-backdrop',
      panelClass: 'time-picker-panel',
    }));
    const portal = new ComponentPortal(TimePickerOverlayComponent);
    const componentRef = overlayRef.attach(portal);
    componentRef.instance.time = `${this.padZero(this.hour)}:${this.padZero(this.minute)}`;
    componentRef.instance.minuteStep = this.minuteStep;
    componentRef.instance.timeChange.subscribe(val => {
      const t = val.split(':');
      this.hour = +t[0] || 0;
      this.minute = +t[1] || 0;
      this.dateChanged(this.model);
    });

    const dialogRef = new TimePickerOverlayRef(overlayRef);

    overlayRef.backdropClick().subscribe(_ => dialogRef.close());
  }
  private padZero(val: number) {
    return `0${val}`.slice(-2);
  }
}
export class TimePickerOverlayRef {

  constructor(private overlayRef: OverlayRef) { }

  close(): void {
    this.overlayRef.dispose();
  }
}
