import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmationDialogComponent } from '.';
import { UIKitButtonModule } from '../ui-kit';
import { UIKitInputModule } from '../ui-kit/input/input.module';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    DialogModule,
    UIKitButtonModule,
    UIKitInputModule,
    FormsModule
  ],
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent]
})
export class ConfirmationDialogModule { }
