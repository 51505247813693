import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';

export const selectProductWizardSaving = () => createSelector(
  (state: AppState) => state,
  (state: AppState) => {
    if (!!state.surveyWizardContext.productId) {
      return state.surveyWizardContext.saving;
    }
    return state.followupWizardContext.saving;
  }
);
export const selectProductWizardUnsaved = () => createSelector(
  (state: AppState) => state,
  (state: AppState) => {
    if (!!state.surveyWizardContext.productId) {
      return state.surveyWizardContext.unsaved;
    }
    return state.followupWizardContext.unsaved;
  }
);
