import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { UIKitButtonModule } from '../button';
import { UIKitIconModule } from '../icon';
import { UIKitSquareButtonModule } from '../square-button/square-button.module';
import { UIKitStep, UIKitStepper } from './stepper.component';

@NgModule({
  declarations: [UIKitStepper, UIKitStep],
  imports: [CommonModule, CdkStepperModule, UIKitButtonModule, UIKitIconModule, TranslocoModule, UIKitSquareButtonModule],
  exports: [UIKitStepper, UIKitStep]
})
export class UIKitStepperModule { }
