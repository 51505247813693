<label (click)="select($event)" class="uikit-radio-label" [class.label-active]="rb.checked" [class.disabled]="disabled"
    [class.label-focus]="focused">
    <input #rb type="radio" class="cdk-visually-hidden" [attr.id]="inputId" [attr.name]="name" [attr.value]="value"
        [attr.tabindex]="tabindex" [attr.aria-checked]="checked" [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledBy" [checked]="checked" [disabled]="disabled" (change)="onChange($event)"
        (focus)="onInputFocus($event)" (blur)="onInputBlur($event)">

    <uikit-icon class="uikit-radio-icon" [class.disabled]="disabled" [icon]="icon">
    </uikit-icon>
    <ng-content></ng-content>
</label>