<section class="insight-stepper">

  <header>

    <div class="steps">
      @for(step of steps; track i; let i = $index) {
        <button class="step" [attr.data-pendo-id]="'product-step-' + (i + 1)"
          [ngClass]="{ 'active': selectedIndex === i, 'visited': selectedIndex > i || step.interacted}"
          (click)="onClick(i)" [disabled]="saving$ | async">
          <span class="step-number-wrapper">
            <span class="step-number">{{ i + 1 }}</span>
          </span>
          <span class="label">{{step.label}}</span>
        </button>
      }

    </div>

    <div class="action-area">
      <button uikit-button min-width small color="ghost" [disabled]="(saving$ | async)"
        (click)="onClose()">{{'INSIGHT.GLOBAL.CLOSE' | transloco}}</button>
    </div>

  </header>

  <div class="step-content">
    <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
  </div>

  <footer>
    <div class="to-top" [ngClass]="{ 'visible': showToTop() }">
      <button uikit-square-button size="small" (click)="scrollToTop()" [focusable]="false">
        <uikit-icon icon="chevron-up-thin"></uikit-icon>
        {{ 'INSIGHT.ADMIN.PRODUCTWIZARD.SCROLL_TO_TOP' | transloco }}
      </button>
    </div>
    <div class="footer-content">
      <ng-content select="[stepper-footer]"></ng-content>
    </div>
    <div class="footer-actions">
      <div class="footer-prev-button-container">
        <button cdkStepperPrevious uikit-button min-width small color="ghost" class="footer-prev-button"
          [disabled]="(saving$ | async) || selectedIndex === 0"
          [attr.data-pendo-id]="'insight_stepper_previous'">{{'INSIGHT.GLOBAL.PREVIOUS' | transloco}}</button>
      </div>
      <button uikit-button min-width small color="ghost" [disabled]="(saving$ | async) || (unsaved$ | async) === false"
        (click)="onSave()" [attr.data-pendo-id]="'insight_stepper_save'">{{'INSIGHT.GLOBAL.SAVE' | transloco}}</button>
      @if(selectedIndex !== (steps.length - 1)){
        <button *ngIf="selectedIndex !== (steps.length - 1)" cdkStepperNext uikit-button min-width small color="primary"
          [disabled]="(saving$ | async) || (linear && !selected?.completed) || selectedIndex === (steps.length - 1)"
          [attr.data-pendo-id]="'insight_stepper_next'">{{'INSIGHT.GLOBAL.NEXT' | transloco}}</button>
      } @else {
        <button *ngIf="selectedIndex === (steps.length - 1)" uikit-button min-width small color="primary"
          [disabled]="(saving$ | async) || !isValidToSubmit()" (click)="finishAction()"
          [attr.data-pendo-id]="'insight_stepper_complete'">{{(finishButtonText ?? 'INSIGHT.GLOBAL.COMPLETE') |
          transloco}}</button>
      }
    </div>
  </footer>
</section>
