<label *ngIf="label" class="label">{{label}}</label>
<igx-date-picker #picker [value]="model" (valueChange)="model = $event" [disabledDates]="disabledDates"
  [formatter]="formatter" mode="dialog" [placeholder]="' '">

  <igx-picker-toggle igxSuffix>
    <uikit-icon icon="calendar" [block]="true"></uikit-icon>
  </igx-picker-toggle>
  <igx-suffix (click)="$event.stopPropagation();showTimePicker()">
    <uikit-icon [block]="true" icon="watch"></uikit-icon>
  </igx-suffix>
  <igx-picker-clear igxSuffix> </igx-picker-clear>
</igx-date-picker>