import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrilliantBarsComponent } from './brilliant-bars.component';

@NgModule({
    declarations: [BrilliantBarsComponent],
    imports: [CommonModule],
    exports: [BrilliantBarsComponent]
})
export class BrilliantBarsModule { }
