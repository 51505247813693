import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UIKitIconModule} from "app/shared/ui-kit/icon";
import {UIKitButtonModule} from "app/shared/ui-kit";
import {UIKitFormFieldModule} from "app/shared/ui-kit/form-field/form-field.module";
import {
  UIKitActionItemComponent,
  UIKitActionListComponent,
  UIKitActionMenuComponent,
  UIKitActionMenuItemComponent
} from "app/shared/ui-kit/action-list/action-list";
import {MatMenuModule} from "@angular/material/menu";
import {PipesModule} from "app/shared/core/pipes/pipes.module";
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
  imports: [
    CommonModule,
    UIKitIconModule,
    UIKitButtonModule,
    UIKitFormFieldModule,
    MatMenuModule,
    PipesModule,
    TranslocoModule,
  ],
  declarations: [UIKitActionListComponent, UIKitActionItemComponent, UIKitActionMenuComponent, UIKitActionMenuItemComponent],
  exports: [UIKitActionListComponent, UIKitActionItemComponent, UIKitActionMenuComponent, UIKitActionMenuItemComponent]
})
export class UIKitActionListModule { }
