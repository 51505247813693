import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: ' button[uikit-square-button]'
})
export class UIKitSquareButton implements OnInit {
  @Input() size: 'small' | 'big';
  @HostBinding('class.ui-kit-square-button-small') get small() { return this.size === 'small' }
  @HostBinding('class.ui-kit-square-button-big') get big() { return this.size === 'big' }

  @HostBinding('class.ui-kit-square-button--selected')
  @Input() selected = false;

  @HostBinding('class.skeleton')  @HostBinding('class.ui-kit-square-button--skeleton') @Input() skeleton = false;

  @Input() focusable = true;

  constructor(private element: ElementRef) {
    ((element.nativeElement as HTMLElement)).classList.add('ui-kit-square-button');
  }
  ngOnInit(): void {
    if (!this.focusable) {
      ((this.element.nativeElement as HTMLElement)).classList.add('ui-kit-not-focusable');
    }
  }
}
