import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { HasPermissionDirective } from '.';
import { BrilliantBarsModule } from '../brilliant-bars/brilliant-bars.module';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { UIKitModule } from '../ui-kit/uikit.module';
import { SkeletonModule } from '../ui/skeleton/skeleton.module';
import { FeatureFlagDirective } from './feature-flag.directive';
import { IndexIconPipe } from './pipes/index-icon.pipe';
import { PipesModule } from './pipes/pipes.module';


@NgModule({
  declarations: [
    IndexIconPipe,
    FeatureFlagDirective
  ],
  imports: [
    CommonModule,
    SkeletonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    BrilliantBarsModule,
    UIKitModule,
    PipesModule,
    ConfirmationDialogModule
  ],
  exports: [
    CommonModule,
    SkeletonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    HasPermissionDirective,
    UIKitModule,
    BrilliantBarsModule,
    PipesModule,
    ConfirmationDialogModule,
    IndexIconPipe,
    FeatureFlagDirective
  ]
})
export class CoreModule {
}
